@media print {
  @page {
    margin: 0.5in
  }
  .screen-only {
    display: none
  }
  .print-only {
    display: visible
  }
}

@media screen {
  .screen-only {
    display: visible
  }
  .print-only {
    display: none
  }
}
